.hover-ef::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0);
  transition: background-color 0.15s ease-in-out;
  pointer-events: none;
}

.highlight-hover-ef:hover::after,
.highlight-hover-ef:active::after,
.highlight-hover-ef:focus::after {
  background-color: var(--grey-5);
}

.w-animate-1 { transition: width 0.1s ease-in, max-width 0.1s ease-in; }
.w-animate-3 { transition: width 0.3s ease-in, max-width 0.3s ease-in; }

.l-animate-3 { transition: left 0.3s ease-in-out; }

.o-animate-3 { transition: opacity .3s ease-in, visibility .3s ease-in;}

/*Classes to add a nice animation to the Modals, keeping original names for easier referencing*/
/*https://github.com/reactjs/react-modal/blob/master/docs/styles/transitions.md*/
.ReactModal__Content {
  transform: translateY(20px);
  transition: transform 400ms ease-out;
  overflow-y: visible;
  max-height: 80vh;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}
.ReactModal__Overlay--after-open { opacity: 1; }
.ReactModal__Overlay--before-close { opacity: 0; }
.ReactModal__Content--after-open { transform: translateY(0); }
.ReactModal__Content--before-close { transform: translateY(100px); }

/* Dropdown animation*/
.expand-keyframe-ef {
  animation: expand--in .3s ease-in-out;
}

@keyframes expand--in {
  0% {
    max-height: 0px
  }

  100% {
    max-height: 500px;
  }
}

/*Fade in*/
.fade-in-keyframe-ef {
  animation: fade--in .3s ease-in forwards;
}
/*Tooltip aniation*/
.slide-in-bottom-tooltip-keyframe-ef {
  left: 50%;
  animation: fade--in-center .15s ease-in forwards;
}
/* Dropdown animation*/
.slide-in-bottom-keyframe-ef {
  animation: fade--in-bottom .3s ease-in-out;
}

@keyframes fade--in-center {
  0% {
    opacity: 0;
    transform: translateY(calc(100% + 30px)) translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(calc(100% + 20px)) translateX(-50%);
  }
}

@keyframes fade--in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes fade--in-bottom {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Keeps rotating the element (i.e. loading) */
.rotate360-keyframe-ef {
  animation: rotate360 1s linear infinite;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
